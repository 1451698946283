<template>
  <page-container class="rts-settings" :title="$t('pages.titles.rtsSettings')">
    <titled-card title="API РТС-тендер">
      <base-form v-model="formModel" :disabled="isPending" :configuration="formConfiguration" />

      <template #actions>
        <v-btn color="secondary" :disabled="hasNotChanges || isPending" small depressed @click="resetSettings()">
          Отменить
        </v-btn>

        <v-btn
          class="ml-3"
          color="primary"
          :disabled="hasNotChanges || isPending"
          :loading="isPending || pendingTasks.saveSettings"
          small
          depressed
          @click="saveSettings()"
        >
          Применить
        </v-btn>
      </template>
    </titled-card>
  </page-container>
</template>

<script>
  import _ from 'lodash'
  import PendingTasksManagerMixin, { handlePendingTask } from 'geoportal/src/mixins/PendingTasksManagerMixin'
  import { showMessage } from '@/utils/messages'
  import { createFormConfiguration, formFieldPresets } from '@/components/BaseForm/formHelpers'
  import { formatTextInputValue } from '@/utils/forms'

  const formConfiguration = createFormConfiguration([
    [{ id: 'base_url', label: 'Хост', bind: { autocomplete: 'url' } }, formFieldPresets.text],
    [{ id: 'auth_base_url', label: 'Хост (сервер авторизации)', bind: { autocomplete: 'url' } }, formFieldPresets.text],
    [{ id: 'method_login', label: 'Метод для авторизации' }, formFieldPresets.text],
    [{ id: 'method_get_access_token', label: 'OAuth. Метод получения access token' }, formFieldPresets.text],
    [{ id: 'method_refresh_token', label: 'OAuth. Метод обновления access token' }, formFieldPresets.text],
    [{ id: 'method_user_profile', label: 'OAuth. Метод получения пользователя' }, formFieldPresets.text],
    [{ id: 'method_areas_set', label: 'Метод отправки участка' }, formFieldPresets.text],
    [{ id: 'method_areas_search', label: 'Метод поиска участков' }, formFieldPresets.text],
    [{ id: 'client_id', label: 'OAuth. Client ID' }, formFieldPresets.text],
    [{ id: 'client_secret', label: 'OAuth. Client Secret' }, formFieldPresets.text]
  ])

  export default {
    name: 'RtsSettingsView',
    mixins: [PendingTasksManagerMixin],

    data() {
      return {
        formConfiguration,
        initialSettings: {},
        formModel: {}
      }
    },

    computed: {
      hasNotChanges() {
        return _.isEqual(this.formModel, this.initialSettings)
      }
    },

    created() {
      this.fetchSettings()
    },

    methods: {
      setModels(settings) {
        this.initialSettings = settings.rts
        this.formModel = _.cloneDeep(this.initialSettings)
      },

      @handlePendingTask()
      async fetchSettings() {
        const result = await this.$api.backend.settings.get_settings()
        this.setModels(result)
      },

      resetSettings() {
        this.formModel = _.cloneDeep(this.initialSettings)
      },

      @handlePendingTask()
      @handlePendingTask('saveSettings')
      async saveSettings() {
        const formData = _.pickBy(
          _.mapValues(this.formModel, formatTextInputValue),
          (value, key) => !_.isEqual(value, this.initialSettings[key])
        )

        const result = await this.$api.backend.settings.update_settings({ rts: formData })
        this.setModels(result)
        showMessage('Настройки успешно обновлены')
      }
    }
  }
</script>

<style lang="scss" scoped>
  .rts-settings {
    .titled-card {
      &::v-deep .v-sheet {
        padding-bottom: 4px !important;
      }
    }
  }
</style>
